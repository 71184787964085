.detail {
  text-align: center;
  background-color: get-color("white");
  margin-bottom: 105px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 47px;
    height: 47px;
    background-color: get-color("white");
    z-index: 0;
    pointer-events: none;
  }

  @include breakpoint(small only) {
    margin-bottom: 72px;
  }

  &__inner {
    width: 100%;
    max-width: 1344px;
    margin: 0 auto;
    padding: 105px 42px 90px;

    @include breakpoint(small only) {
      padding: 72px 18px;
    }
  }

  &__features {
    width: 100%;
    max-width: 580px;
    margin: 0 auto 42px;
    padding: 0 21px;

    @include breakpoint(small only) {
      margin-bottom: 36px;
      padding: 0 18px;
    }

    h2 {
      @include module-panel-heading;
      color: get-color("dark-blue");
      margin-bottom: 42px;

      @include breakpoint(small only) {
        margin-bottom: 27px;
      }
    }

    &-list {
      text-align: left;

      @include breakpoint(medium) {
        transform: translateX(63px);
      }

      ul {
        margin: 0;
      }

      li {
        @include bullet-list();
        margin-bottom: 0;
      }
    }
  }

  &__rate {
    @include price-accordion-heading;
    color: get-color("dark-blue");
    margin-bottom: 32px;

    @include breakpoint(small only) {
      margin-bottom: 27px;
    }
  }
}
