.footer {
  width: 100%;
  background-color: transparent;

  @include breakpoint(small only) {
    text-align: center;
  }

  a {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -20px;
      transform: translateY(-50%) rotate(45deg);
      width: 6px;
      height: 6px;

      transition: all 0.3s ease-in-out;
    }

    &:hover,
    &:focus-visible {
      background-color: transparent !important;
      color: get-color("mid-blue");

      &::before {
        background-color: get-color("mid-blue");
        border: 1px solid transparent !important;
        transform: translateY(-50%) rotate(225deg);
      }
    }
  }

  &__newsletter {
    position: relative;

    &-inner {
      background-color: get-color("mid-blue");
      margin-top: -1px;
    }

    &-bevel {
      height: 46px;
      background-color: get-color("mid-blue");

      clip-path: polygon(
        0 0,
        0 100%,
        100% 100%,
        100% calc(100% - 12px),
        calc(100% - 35px) 0
      );

      &:nth-of-type(odd) {
        clip-path: polygon(
          100% 0,
          100% 100%,
          0 100%,
          0 calc(100% - 12px),
          35px 0
        );
      }
    }

    &-diamond {
      position: absolute;
      top: -26px;
      left: 50%;
      transform: translateX(-50%);

      img {
        width: 53px;
        height: 53px;
        object-fit: cover;
      }
    }

    &-header {
      max-width: 1260px;
      margin: 0 auto;
      width: 100%;
      background-color: get-color("mid-blue");

      &-inner {
        padding: 0 21px;
      }
    }

    &-form {
      max-width: 1260px;
      margin: 0 auto;
      width: 100%;
      background-color: get-color("mid-blue");
      padding-bottom: 31px;

      @include breakpoint(small only) {
        text-align: left;
        padding-bottom: 12px;
      }
    }

    h2 {
      font-family: $primary-font;
      font-size: 35px;
      line-height: 50px;
      color: get-color("white");
      margin-bottom: 9px;

      @include breakpoint(small only) {
        text-align: center;
        margin-bottom: 18px;
      }
    }

    input[type="text"],
    input[type="email"] {
      width: 100%;
      height: 53px;
      background-color: get-color("pale-blue");
      font-family: $secondary-font;
      font-size: 10px;
      line-height: 1;
      letter-spacing: 0.2em;
      font-weight: 600;
      color: get-color("dark-grey");
      text-transform: uppercase;
      padding-left: 25px;

      &::placeholder {
        font-family: $secondary-font;
        font-size: 10px;
        line-height: 1;
        letter-spacing: 0.2em;
        font-weight: 600;
        color: get-color("dark-grey");
        text-transform: uppercase;
      }
    }

    form {
      position: relative;
      background-color: get-color("mid-blue");

      .form__label {
        left: 25px;
        text-transform: uppercase !important;
        font-family: $secondary-font !important;
        font-size: 7px !important;
        line-height: 1 !important;
        font-weight: 600 !important;
        letter-spacing: 0.2em !important;
      }
    }

    form .cell {
      padding: 0 21px 1px;

      @include breakpoint(small only) {
        margin-bottom: 18px;
        padding: 0 18px 18px;
      }
    }

    .cell.medium-6 {
      padding-right: 10px;

      @include breakpoint(small only) {
        padding: 0 18px 18px;
      }

      &:nth-of-type(even) {
        padding-left: 10px;
        padding-right: 21px;

        @include breakpoint(small only) {
          padding: 0 18px 0;
        }

        .form__label {
          left: 16px;

          @include breakpoint(small only) {
            left: 25px;
          }
        }
      }
    }

    &-submit {
      position: absolute;
      right: -105px;
      top: 15px;
      min-width: 120px;
      padding-bottom: 0;
      margin-bottom: 0;

      @include breakpoint(medium only) {
        top: auto;
        bottom: -10px;
        right: 0;
      }

      @include breakpoint(small only) {
        position: static;
        text-align: center;
      }

      button {
        color: get-color("white");
        font-family: $primary-font;
        font-size: 25px;
        line-height: 1;
        cursor: pointer;
        transition: 0.3s all ease-in-out;

        &:hover,
        &:focus-visible {
          color: get-color("dark-blue");
        }
      }
    }

    label {
      color: get-color("white");
      font-family: $secondary-font;
      text-transform: none !important;
      font-size: 12px !important;
      line-height: 24px;
      font-weight: 300;
      letter-spacing: 0 !important;

      a {
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: from-font;
        transition: all 0.3s ease-in-out;

        &::before {
          opacity: 0;
        }

        &:hover {
          text-underline-offset: 4px;
        }
      }
    }

    .form__checkbox {
      input[type="checkbox"] {
        ~ label {
          color: #fff;
        }

        ~ label::before {
          background-color: transparent;
          border: 2px white solid;
          opacity: 0.5;
        }
      }
    }

    .cell.medium-6,
    .cell.small-12 {
      margin-bottom: 0px;
    }
  }

  &__main {
    background: url("img/new/pattern-connected-q.svg") repeat-x;
    background-color: get-color("dark-blue");
    padding: 145px 0 63px;
  }

  &__inner {
    max-width: 1344px;
    width: 100%;
    margin: 0 auto;
    padding: 0 42px;

    @include breakpoint(medium down) {
      padding: 42px 21px 42px;
    }

    @include breakpoint(small only) {
      padding: 18px;
    }
  }

  &__social {
    padding-left: 21px;

    @include breakpoint(medium down) {
      padding-left: 0;
    }

    &-icons {
      margin: 20px 0;
      list-style-type: none;
    }

    li {
      display: inline-block;
      margin: 0 9px;
    }

    img,
    svg {
      width: auto;
      height: 17px;
    }
  }

  &__primary,
  &__secondary {
    ul {
      margin: 0;
      list-style-type: none;
    }

    li {
      white-space: nowrap;
    }

    a {
      position: relative;
      color: get-color("white");
      font-family: $secondary-font;
      font-size: 11px;
      line-height: 19px;
      font-weight: 600;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      &:hover,
      &:active {
        &::after {
          width: 30px;
        }
      }
    }
  }

  &__copyright {
    margin-top: 6px;

    @include breakpoint(medium down) {
      margin-top: 54px;
    }

    p {
      color: get-color("white");
      font-family: $secondary-font;
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 21px;

      a {
        color: inherit;
        display: block;
      }
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-icons {
      @include breakpoint(small only) {
        text-align: center;
      }

      path,
      circle {
        fill: #fff;
        transition: all 0.3s ease-in-out;
      }

      a:hover path,
      a:hover circle {
        fill: get-color("mid-blue");
      }
    }

    &-80days {
      margin-left: 3px;
      p {
        font-family: $secondary-font;
        font-size: 11px;
        line-height: 21px;
        color: get-color("white");

        a {
          color: inherit;

          &:hover,
          &:focus-visible {
            color: get-color("mid-blue");
          }
        }
      }
    }
  }
}
