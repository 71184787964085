.pagination {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto 60px;
  text-align: center;

  @include breakpoint(large) {
    padding-bottom: 27px;
  }

  a {
    &:hover {
      background-color: transparent;
    }
  }

  &-previous,
  &-next {
    a {
      position: relative;
      display: inline-block;
      height: 33px;
      line-height: 21px;
      font-family: $secondary-font;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-size: 15px;
      color: #333333;

      &::before {
        display: none;
      }

      &::after {
        content: "";
        position: absolute;
        top: -2px;
        width: 21px;
        height: 33px;
        background: no-repeat;
        background-size: 27px 21px;
        transition: all 0.3s ease-in-out;
        transform: rotate(-4deg);
      }

      &:hover,
      &:active {
        &::after {
          width: 27px;
        }
      }
    }
  }

  &-previous {
    float: left;

    a {
      padding-left: 37px;
      margin-left: 20px;

      @include breakpoint(large) {
        margin-left: 70px;
      }

      &::after {
        left: 10px;
        background-image: url("img/new/chevron-left-dark.svg");
        background-position: left center;
        background-size: contain;
      }

      &:hover {
        &::after {
          background-image: url("img/new/chevron-left-orange.svg");
        }
      }
    }
  }

  &-next {
    float: right;

    a {
      padding-right: 37px;
      margin-right: 20px;

      @include breakpoint(large) {
        margin-right: 70px;
      }

      &::after {
        right: 10px;
        background-image: url("img/new/chevron-right-dark.svg");
        background-position: right center;
        background-size: contain;
      }
      &:hover {
        &::after {
          background-image: url("img/new/chevron-right-orange.svg");
        }
      }
    }
  }
}
