.home-directions {
  background: url("img/new/pattern-connected-q-light.svg") repeat-x;
  background-position: bottom;
  background-color: get-color("dark-blue");

  &__inner {
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
    padding: 42px 0 63px;

    @include breakpoint(small only) {
      padding: 36px 36px 162px;
    }
  }

  &__intro {
    @include breakpoint(medium) {
      padding-left: 63px;
      padding-top: 120px;
    }

    h2 {
      font-family: $primary-font;
      font-size: 48px;
      line-height: 1;
      color: get-color("white");
      margin-bottom: 9px;
    }

    h3 {
      @include subheading;
      margin-bottom: 32px;
      font-weight: 600;
    }

    p {
      @include body-copy;
      color: get-color("white");
      margin-bottom: 32px;
    }

    a.button {
      color: get-color("white");
    }
  }

  &__image {
    text-align: right;

    @include breakpoint(small only) {
      padding-top: 45px;
      margin-bottom: 45px;
    }

    img {
    }
  }

  &__content {
    @include breakpoint(medium) {
      padding-top: 120px;
      transform: translateX(-21px);
    }

    h4 {
      font-family: $primary-font;
      font-size: 25px;
      line-height: 36px;
      color: get-color("white");
    }

    ul {
      list-style: none;
      margin: 0;

      li {
        font-family: $secondary-font;
        font-size: 15px;
        line-height: 26px;
        font-weight: 300;
        color: get-color("white");
      }
    }
  }
}
