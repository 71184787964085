.off-canvas {
  z-index: 50000;
  width: 100%;
  transform: translateX(-100%);
  background: url("img/new/pattern-connected-q-light.svg") repeat-x;
  background-color: get-color("white");
  background-position: bottom;

  &.is-open {
    transform: translateX(0);
  }

  &__inner {
    position: relative;
    max-width: 1260px;
    margin: auto;
    padding: 126px 21px 63px;
    min-height: 100svh;

    @include breakpoint(small only) {
      padding: 84px 0 126px;
    }
  }

  &__close {
    position: absolute;
    top: 42px;
    left: 33px;
    width: 37px;
    height: 37px;
    cursor: pointer;
    background-color: get-color("dark-blue");
    text-indent: -12345px;
    transform: rotate(45deg);

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 20px;
      height: 20px;
      transition: 0.3s all ease-in-out;
    }

    &:hover,
    &:focus-visible {
      img {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }

    @include breakpoint(small only) {
      top: 18px;
      left: 18px;
      width: 34px;
      height: 34px;

      img {
        width: 17px;
        height: 17px;
      }
    }
  }

  &__nav-primary {
    padding-left: 6.64%;
    margin-bottom: 21px;

    @include breakpoint(small only) {
      margin-bottom: 36px;
    }

    a {
      transition: color 0.3s ease-in-out;
      color: get-color("dark-blue");
      font-family: $primary-font;
      font-size: 40px;
      line-height: 45px;

      &:hover,
      &:focus-visible,
      &:active {
        color: get-color("mid-blue");
      }
    }

    > ul {
      margin: 0 0 63px;
      list-style-type: none;

      @include breakpoint(small only) {
        margin-bottom: 36px;
      }

      > li {
        margin-bottom: 17px;

        > a {
          @include breakpoint(small only) {
            font-size: 30px;
          }
        }

        &.open > a {
          color: get-color("mid-blue");
        }

        > ul {
          margin: 18px 0 30px 45px;
          list-style-type: none;
          display: none;

          @include breakpoint(small only) {
            margin-left: 0;
          }

          > li {
            margin-bottom: 19px;

            @include breakpoint(small only) {
              line-height: 1;
              margin-bottom: 18px;
            }

            > a {
              font-size: 24px;
              line-height: 38px;

              @include breakpoint(small only) {
                font-size: 20px;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  }

  &__nav-header {
    width: 100%;

    @media screen and (max-width: $offcanvas-breakpoint) {
      display: block;
    }

    ul {
      margin: 0 auto;
      list-style-type: none;
    }

    a {
      font-family: $secondary-font;
      font-size: 20px;
      line-height: 45px;
      font-weight: 500;
      color: get-color("dark-grey");
      transition: color 0.3s ease-in-out;

      &:hover,
      &:active {
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: from-font;
      }
    }
  }

  .footer__newsletter {
    padding-bottom: 100px;
  }

  &__offer {
    padding: 0 4.6%;
    text-align: center;

    img {
      @include corner-cut;
    }

    h2 {
      font-family: $primary-font;
      color: get-color("dark-blue");
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 0;

      a {
        color: get-color("dark-blue");
      }
    }

    @media screen and (max-width: 1050px) {
      display: none;
    }

    &-price {
      font-family: $primary-font;
      color: get-color("dark-blue");
      font-size: 22px;
      line-height: 36px;
      margin-bottom: 21px;
    }

    &-content {
      margin-bottom: 42px;
      padding: 32px 42px 42px;
      background-color: #f9f9f9;

      @include breakpoint(small only) {
        margin-bottom: 36px;
      }

      p {
        font-family: $secondary-font;
        color: get-color("dark-grey");
        font-size: 15px;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 32px;
      }
    }

    .multiple-buttons {
      text-align: center;
      .button {
        margin-bottom: 21px;
      }
    }
  }
}

.datepicker-panel {
  font-family: $primary-font;
}
