.contact {
  width: 100%;
  max-width: 1344px;
  margin: 0 auto 180px;
  position: relative;
  padding: 0 42px;

  @include breakpoint(small only) {
    padding: 0 18px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 47px;
    height: 47px;
    background-color: get-color("white");
    z-index: 0;
    pointer-events: none;
  }

  &__details {
    padding-top: 117px;

    @include breakpoint(small only) {
      padding: 18px 18px 0;
    }

    @include breakpoint(medium) {
      padding-right: 21px;
    }

    @include breakpoint(large) {
      padding-right: 42px;
    }

    h2 {
      @include price-accordion-heading;
      margin-bottom: 9px;
    }

    p {
      @include body-copy();
      font-weight: 300;
    }

    a {
      color: inherit;
      transition: 0.3s all ease-in-out;

      &:hover,
      &:focus-visible {
        color: get-color("mid-blue");
        text-decoration: underline;
        text-decoration-thickness: from-font;
        text-underline-offset: 2px;
      }
    }
  }

  &__form {
    background-color: white;
    padding: 47px 42px;

    @include breakpoint(small only) {
      padding: 48px 18px 54px;
    }

    h2 {
      @include module-panel-heading;
      color: get-color("dark-blue");
      margin-bottom: 32px;

      @include breakpoint(small only) {
        // margin-bottom: 46px;
      }
    }

    .button {
      margin-bottom: 0;

      @include breakpoint(small only) {
        margin-top: 20px;
      }
    }

    .selectric span.label {
      letter-spacing: 0.2em;
    }

    .form__checkbox {
      input[type="checkbox"] ~ label {
        padding-left: 23px;
        font-weight: 600;
      }
    }
  }

  &__diamond {
    left: 50%;
    position: relative;
    width: 100%;
    transform: translate(-30px, -35px);

    path {
      fill: white;
    }
  }

  .form__checkbox {
    p {
      margin-top: 18px;
      font-size: 12px;
      font-family: $secondary-font;
      line-height: 1;
      font-weight: 300;
      color: get-color("mid-blue");
    }
  }

  .field-validation-error {
    color: #a70000;
  }

  .grecaptcha-badge {
    visibility: hidden;
    pointer-events: none;
  }

  .form label.error {
    text-transform: none !important;
    color: #a70000 !important;
    letter-spacing: none !important;
    font-size: 12px !important;
    padding-left: 0 !important;

    &::before {
      background-color: transparent !important;
    }
  }
}
