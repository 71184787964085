.social-wall {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 63px;

  @include breakpoint(small only) {
    padding-bottom: 54px;
  }

  h3 {
    font-family: $primary-font;
    font-size: 35px;
    line-height: 50px;
    color: get-color("dark-blue");
    margin-bottom: 0;

    a {
      color: inherit;
    }
  }

  p {
    font-family: "Tenez";
    color: get-color("dark-blue");
    font-size: 16px;
    letter-spacing: 0.025em;
    margin-bottom: 42px;

    a {
      color: inherit;
    }
  }

  &__feed {
    padding: 0 52px;

    @include breakpoint(small only) {
      padding: 0 36px;
    }

    .crt-feed {
      @include breakpoint(medium) {
        width: calc(100% + 24px);
        margin-left: -12px;
        margin-right: -12px;
      }
    }

    .crt-grid-post {
      @include breakpoint(medium) {
        padding: 0 10px;
      }
    }
  }
}

.social-wall.wrap--narrow {
  max-width: 1050px;
  width: 100%;
  margin: -10px auto 25px;

  @include breakpoint(medium down) {
    padding-right: 40px;
    padding-left: 40px;
  }
}
