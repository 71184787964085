.landing {
  padding-top: 63px;
  margin-bottom: 84px;

  @include breakpoint(small only) {
    padding-top: 18px;
    margin-bottom: 36px;
  }

  &__inner {
  }

  &__slider,
  &__content {
    transition: transform cubic-bezier(0.5, 0.01, 0.3, 1.01),
      opacity cubic-bezier(0.5, 0.01, 0.3, 1.01);
    will-change: transform, opacity;
    backface-visibility: hidden;
    transition-duration: 1250ms;
    opacity: 0;
  }

  &__item {
    max-width: 1344px;
    width: 100%;
    margin: 0 auto 124px;

    &:last-of-type {
      margin-bottom: 105px;
    }

    @include breakpoint(small only) {
      margin-bottom: 18px;
      padding: 0 18px;

      &:last-of-type {
        margin-bottom: 18px;
      }
    }

    &:nth-child(even) {
      .landing__slider {
        transform: translateX(100px);

        @include breakpoint(medium) {
          order: 2;
          margin-left: 0;
        }

        &.landing-alternating-fade-in {
          transform: translateX(0);
          opacity: 1;
        }
      }

      .landing__content {
        transform: translateX(-100px);

        @include breakpoint(medium) {
          order: 1;
        }

        @include breakpoint(large) {
          margin-left: 8.33333%;
        }

        &.landing-alternating-fade-in {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    &:nth-child(odd) {
      .landing__slider {
        transform: translateX(-100px);

        &.landing-alternating-fade-in {
          transform: translateX(0);
          opacity: 1;
        }
      }

      .landing__content {
        transform: translateX(100px);

        &.landing-alternating-fade-in {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  &__slider {
    position: relative;

    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div,
    &-inner {
      height: 100%;
    }

    &-image {
      height: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .reform-slider-arrows {
      display: none;
    }
  }

  &__content {
    padding: 63px 42px;
    @include flex-align-center();
    text-align: center;

    @include breakpoint(medium only) {
      padding: 42px 21px;
    }

    @include breakpoint(small only) {
      padding: 36px 0;
    }

    h2 {
      @include module-panel-heading;
      color: get-color("dark-blue");
      margin-bottom: 3px;

      a {
        color: inherit;
      }
    }

    h3 {
      @include subheading();
      margin-bottom: 31px;

      a {
        color: inherit;
      }
    }

    p {
      @include body-copy();
      margin-bottom: 21px;
    }

    ul {
      text-align: left;
      margin-left: 0;
      margin-bottom: 32px;
      list-style-position: inside;
      list-style: none;
      padding: 0 18px;

      li {
        @include bullet-list;
        font-weight: 400;
      }
    }

    .multiple-buttons {
      padding-top: 38px;
      display: flex;
      justify-content: center;

      @include breakpoint(small only) {
        text-align: center;
        padding-top: 18px;
      }
    }
  }

  &__full-width {
    position: relative;
    margin: 0 auto 84px;

    @include breakpoint(small only) {
      margin-bottom: 54px;
    }

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(get-color("dark-blue"), 0.5);
    }

    &-content {
      padding: 168px 0 147px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;

      @include breakpoint(medium down) {
        padding: 126px 0 105px;
      }

      @include breakpoint(small only) {
        padding: 36.3% 0;
      }

      &-inner {
        text-align: center;
        width: 50%;
        z-index: 2;
        margin: 0 auto;

        @include breakpoint(medium only) {
          width: 85%;
        }

        @include breakpoint(small only) {
          width: calc(100% - 36px);
        }

        h2 {
          @include full-width-panel;
          color: get-color("white");
        }

        h3 {
          @include subheading;
          margin-bottom: 0;
        }
      }
    }
  }
}
