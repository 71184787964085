// https://stackoverflow.com/questions/17181849/placeholder-mixin-scss-css
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}

@mixin reset-list {
  list-style: none;
  margin: 0;
}

@mixin inline-list {
  display: inline-block;

  > li {
    display: inline-block;
  }
}

@mixin flex-align-center {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

@mixin flex-align-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
}

@mixin dining-section {
  @each $complex in selector-parse(&) {
    @at-root body.theme-restaurant #{$complex} {
      @content;
    }
  }
}

@mixin corner-cut {
  clip-path: polygon(0 100%, 100% 100%, 100% 0, 80px 0, 0 80px);

  @include breakpoint(small only) {
    clip-path: polygon(0 100%, 100% 100%, 100% 0, 40px 0, 0 40px);
  }
}

@mixin corner-cut-right {
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 80px, calc(100% - 80px) 0);

  @include breakpoint(small only) {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 40px, calc(100% - 40px) 0);
  }

}
