.venues {
  max-width: 1344px;
  width: 100%;
  margin: 0 auto;
  padding: 63px 42px 0;

  @include breakpoint(small only) {
    padding: 36px 18px 0;
  }

  &__controls {
    text-align: center;
    width: 100%;
    max-width: 540px;
    margin: 0 auto 63px;

    @include breakpoint(small only) {
      margin-bottom: 45px;
    }
  }

  &__filters {
    position: relative;
    display: inline-block;
    font-size: 13px;
    line-height: 15px;
    font-family: $secondary-font;
    font-weight: 600;
    color: get-color("dark-blue");
    text-align: left;
    margin-bottom: 11px;

    @include breakpoint(small only) {
      margin-bottom: 18px;
    }

    &::after {
      @include breakpoint(small only) {
        content: "";
        position: absolute;
        top: 5px;
        right: 0;
        width: 13px;
        height: 13px;
        background-size: 13px 13px;
        transition: transform 0.3s ease-in-out;
      }
    }

    &.open::after {
      transform: rotate(90deg);
    }
  }

  &__filter-fields {
    align-items: flex-end;

    @include breakpoint(small only) {
      text-align: left;
    }
  }

  &__dropdown {
    display: inline-block;
    vertical-align: top;
    width: 44%;

    @include breakpoint(small only) {
      width: 50%;
      display: block;
      margin-bottom: 18px;
    }

    .venues__field-container {
      position: relative;

      @include breakpoint(small only) {
        max-width: 215px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 8px;
        right: 10px;
        border: solid get-color("dark-blue");
        border-width: 1px 1px 0 0;
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
      }

      &:has(input:focus-within) {
        label.venues__field-container-label {
          opacity: 0.34;
        }
      }

      &-label {
        position: absolute;
        top: -4px;
        left: 4px;
        font-family: $secondary-font;
        font-size: 7px;
        line-height: 1;
        font-weight: 600;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: get-color("dark-grey") !important;
        opacity: 0;
        transition: 0.3s all ease-in-out;
      }
    }

    .selectric {
      height: 26px;
      margin: 0;
      border: solid rgba(#707070, 0.5);
      border-width: 0 0 1px;
      background: transparent;
      padding-bottom: 28px;

      @include breakpoint(small only) {
        margin-left: 0;
      }

      .label,
      .button {
        height: 26px;
        line-height: 26px;
        font-family: $secondary-font;
        color: get-color("dark-blue");
        font-size: 15px;
        font-weight: 600;
      }

      .label {
        color: get-color("dark-grey");
        margin-left: 0;
        margin-right: 17px;
        text-align: left;
      }

      .button {
        background-size: 8px 12px;
        color: transparent;

        &::before,
        &::after {
          content: none;
        }
      }

      &-items {
        li {
          font-family: $secondary-font;
          font-size: 11px;
          font-weight: 600;
          color: get-color("dark-grey");

          &:hover,
          &:focus-visible,
          &.highlighted {
            background-color: get-color("pale-pink");
            color: get-color("dark-blue");
          }
        }
      }
    }

    input[type="number"],
    input[type="text"] {
      font-family: $secondary-font;
      width: 100%;
      height: 26px;
      margin: 0;
      background-color: transparent;
      border: solid rgba(#707070, 0.5);
      color: get-color("dark-blue");
      border-width: 0 0 1px;
      font-size: 15px;
      font-weight: 600;
      padding-bottom: 10px;

      @include breakpoint(small only) {
        margin-left: 0;
      }

      &::placeholder {
        font-family: $secondary-font;
        color: get-color("dark-blue");
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  &__error-message {
    text-align: center;
  }

  &__view {
    display: inline-block;
    padding-left: 21px;
    text-align: left;
    padding-bottom: 2px;

    @include breakpoint(small only) {
      margin-top: 20px;
    }

    span {
      display: block;
      font-family: $secondary-font;
      color: get-color("dark-blue");
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 14px;
    }

    button {
      display: inline-block;
      height: 22px;
      text-indent: -12345px;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
    }

    &-button-grid {
      margin-right: 10px;
      width: 20px;
      background: url("img/new/grid-view-grey.svg") no-repeat center center;
      background-size: cover;

      &:hover,
      &:focus-visible,
      &.active {
        background: url("img/new/grid-view-dark-blue.svg") no-repeat center
          center;
        background-size: cover;
      }
    }

    &-button-list {
      width: 23px;
      background: url("img/new/list-view-grey.svg") no-repeat center center;
      background-size: cover;

      &:hover,
      &:focus-visible,
      &.active {
        background: url("img/new/list-view-dark-blue.svg") no-repeat center
          center;
        background-size: cover;
      }
    }
  }

  &__rooms {
    text-align: center;
  }

  &__item {
    margin-bottom: 63px;

    @include breakpoint(small only) {
      padding: 0 18px;
    }

    &:last-child {
      margin: 0;
    }

    &-image {
      @include corner-cut;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-content {
      background-color: #fff;
      padding: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;

      @include breakpoint(small only) {
        padding: 36px 18px;
      }

      h2 {
        margin-bottom: 9px;
        padding: 0;

        a {
          font-family: $primary-font;
          font-size: 35px;
          line-height: 36px;
          color: get-color("dark-blue");
        }
      }

      h3 {
        color: get-color("mid-blue");
        text-transform: uppercase;
        font-size: 11px;
        line-height: 19px;
        letter-spacing: 0.2em;
        font-family: $secondary-font;
        margin-bottom: 21px;

        @include breakpoint(small only) {
          margin-bottom: 18px;
        }
      }

      &-heading {
        margin-bottom: 22px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
      }

      p {
        @include body-copy;
        margin-bottom: 21px;

        @include breakpoint(small only) {
          margin-bottom: 18px;
        }
      }

      .multiple-buttons {
        padding-top: 42px;
        text-align: left;

        @include breakpoint(small only) {
          padding-top: 36px;
        }
      }
    }
  }

  &__rooms--grid-view {
    margin: 0 -21px;

    .venues {
      &__item {
        width: 31%;
        margin: 0 1% 63px;
        display: inline-block;
        vertical-align: top;

        @include breakpoint(medium down) {
          width: 47%;
        }

        @include breakpoint(small only) {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 36px;
        }

        &-image,
        &-content {
          display: block;
          width: 100%;
          text-align: center;

          &-header {
            width: auto;

            h2 {
              padding: 0 18px;
            }
          }

          .multiple-buttons {
            text-align: center;
          }
        }

        &-image {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-content-more {
          display: none;
        }
      }
    }
  }
}
