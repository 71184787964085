.booking {
  &__logo {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 40px;
  }
  .off-canvas {
    background: get-color("white");

    &__inner {
      background: url("img/new/pattern-connected-q-light.svg") repeat-x;
      background-color: get-color("white");
      background-position: bottom;
    }

    &__close {
      position: absolute;
      top: 42px;
      left: auto;
      right: 33px;
      width: 37px;
      height: 37px;
      cursor: pointer;
      background-color: get-color("dark-blue");
      text-indent: -12345px;
      transform: rotate(45deg);

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 20px;
        height: 20px;
        transition: 0.3s all ease-in-out;
      }

      &:hover,
      &:focus-visible {
        img {
          transform: translate(-50%, -50%) rotate(0deg);
        }
      }

      @include breakpoint(small only) {
        top: 18px;
        right: 18px;
        left: auto;
        width: 34px;
        height: 34px;

        img {
          width: 17px;
          height: 17px;
        }
      }
    }
  }

  &__wrapper {
    @include breakpoint(small only) {
      padding: 0;
    }
  }

  &__wrapper-inner {
    padding: 0 63px;

    @include breakpoint(medium only) {
      padding: 0;
    }

    @include breakpoint(small only) {
      padding: 0 18px;
    }
  }

  &__title {
    text-align: center;

    h2 {
      font-family: $primary-font;
      font-size: 40px;
      line-height: 45px;
      color: get-color("dark-blue");
      margin-bottom: 31px;
    }
  }

  .tabs {
    margin-bottom: 35px;
    border: 0;
    background: transparent;
    text-align: center;
    display: flex;
    justify-content: space-between;

    @include breakpoint(small only) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 18px;
    }

    &-title {
      float: none;
      margin: 0 auto;
      flex-grow: 1;
      width: 33%;
      padding: 0 10px;

      @include breakpoint(small only) {
        width: 75%;
        padding: 0;
      }

      > a {
        color: get-color("dark-grey");
        border: none;
        background-color: transparent;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.1em;
        line-height: 1;
        padding: 0;
        padding-left: 17px;
        text-align: left;

        @include breakpoint(small only) {
          margin-bottom: 27px;
        }

        &[aria-selected="true"],
        &:hover,
        &:active {
          color: get-color("dark-grey");

          &::before {
            background-color: get-color("mid-blue");

            body.events & {
              background-color: get-color("pink");
              border-color: get-color("pink");
            }

            body.front-of-house & {
              background-color: get-color("orange");
              border-color: get-color("orange");
            }

            body.dining & {
              background-color: get-color("peach");
              border-color: get-color("peach");
            }
          }
        }
      }
    }

    &-panel {
      padding: 0;
    }

    &-content {
      border: 0;
      background: transparent;
    }
  }

  &__room-fields {
    text-align: center;

    @include breakpoint(small only) {
    }
    @include breakpoint(medium) {
    }

    > .cell {
      margin-bottom: 27px;

      @include breakpoint(medium) {
        margin-bottom: 10px;
        padding: 0 9px;
      }
    }

    .field-name {
      font-family: $secondary-font;
      font-size: 12px;
      letter-spacing: 0.1em;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 50px;
      position: relative;
      z-index: 1;
    }

    .arrival-date,
    .depart-date {
      z-index: 1;
      position: relative;
    }

    .check-in,
    .check-out {
      width: 100%;
      height: 80px;
      //height: 60px;
      //line-height: 60px;
      margin-top: -20px;
      margin-bottom: 0px;
      cursor: pointer;
      font-family: $primary-font;
      font-size: 60px;
      border: 0;
      text-align: center;
      background: transparent;
      color: get-color("white");
      background: get-color("dark-blue");

      @include breakpoint(small only) {
        font-size: 45px;
      }
    }

    .check-in-month-year,
    .check-out-month-year,
    .check-in-day,
    .check-out-day {
      font-family: $secondary-font;
      font-size: 12px;
      line-height: 2;
      letter-spacing: 0.1em;
      font-weight: 600;
      text-transform: uppercase;
      display: block;
    }
  }

  &__room-field-inner {
    position: relative;
    padding: 17px 0;
    height: 100%;
    color: get-color("white");

    @include breakpoint(small only) {
      min-height: 220px;
    }

    &-bg {
      clip-path: polygon(0 100%, 100% 100%, 100% 0, 27px 0, 0 27px);
      position: absolute;
      top: 0;
      left: 0;
      background-color: get-color("dark-blue");
      width: 100%;
      height: 100%;
    }

    .selectric {
      background-color: transparent;
      border: none;
      border-radius: 0;
      margin-bottom: 24px;

      &-wrapper {
        margin-top: 26px;
        margin-bottom: -15px;

        @include breakpoint(medium) {
          margin-bottom: 71px;
          margin-top: -39px;
        }
      }

      &-items {
        font-family: $primary-font;
      }

      .label,
      .button {
        height: 60px;
        line-height: 60px;
      }

      .label {
        color: get-color("white");
        font-family: $primary-font;
        font-size: 60px;
        border: 0;
        padding-left: 0;
        margin-left: 0;
        margin-right: 0;

        @include breakpoint(medium) {
          margin-top: 20px;
        }
      }

      .button {
        right: 0;
        top: 0;
        width: 16px;
        height: 60px;
        border: 0;
        background: url("img/arrow-down-white.svg") no-repeat left center;
        background-size: 16px;

        &::before,
        &::after {
          display: none;
        }

        @include breakpoint(small only) {
          right: 0;
        }

        @include breakpoint(medium) {
          top: 23px;
        }
      }
    }
  }

  &__room-submit {
    padding-top: 42px;
    margin-bottom: 42px;
    text-align: center;

    @include breakpoint(small only) {
      padding-top: 27px;
      margin-bottom: 27px;
    }
  }

  &__room-accordion {
    text-align: center;

    .accordionButton {
      cursor: pointer;
      margin-bottom: 20px;
    }

    .accordionContent p {
      @include body-copy();
      color: get-color("dark-grey");

      a {
        color: inherit;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;
      }
    }
  }

  &__iframe {
    width: 100%;
    max-width: 407px;
    margin: 0 auto;
    background-color: #fff;

    iframe {
      width: 100%;
      min-height: 680px;
    }
  }

  &__tabs-content-venue {
    p {
      text-align: center;

      a {
        color: inherit;
      }
    }
  }

  #table {
    padding-top: 84px;

    @include breakpoint(small only) {
      padding-top: 36px;
    }

    .booking__table-links {
      ul {
        margin: 0 auto;
        list-style: none;

        li {
          width: 33%;
          margin-left: 33%;
          margin-bottom: 21px;

          @include breakpoint(medium only) {
            width: 40%;
            margin-left: 30%;
          }

          @include breakpoint(small only) {
            width: 80%;
            margin-left: 10%;
            margin-bottom: 18px;
          }
          a {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              font-family: $primary-font;
              font-size: 20px;
              line-height: 1;
              letter-spacing: 0.025em;
              color: get-color("dark-blue");
              position: relative;
              margin-bottom: 0;
            }

            span {
              width: 24px;
              height: 1px;
              background-color: get-color("mid-blue");
              transition: all 0.5s ease-in-out;
            }

            &:hover {
              span {
                transform: translateX(50%);
              }
            }
          }
        }
      }
    }
  }

  #meeting {
    padding-top: 63px;

    @include breakpoint(small only) {
      padding-top: 36px;
    }

    .booking__meeting {
      text-align: center;

      @include breakpoint(medium) {
        max-width: 460px;
        width: 100%;
        margin: 0 auto;
      }

      h2 {
        @include module-panel-heading;
        color: get-color("dark-blue");
        margin-bottom: 9px;
      }

      h3 {
        @include subheading;
        margin-bottom: 21px;
      }

      p {
        @include body-copy;
        margin-bottom: 42px;
      }
    }
  }
}

.adults-select {
  background: get-color("dark-blue");
  background: url("img/new/chevron-down-white.svg") no-repeat calc(100% - 15px)
    50px;
  background-size: 12px 6px;
  border: 0;
  color: get-color("white");
  cursor: pointer;
  font-family: $primary-font;
  font-size: 60px;
  height: 80px;
  margin-bottom: 0;
  margin-top: -20px;
  text-align: center;
  width: 100%;
}

.booking__room-field-inner .selectric .label {
  margin-top: 29px;
}
