.blog-detail {
  width: 100%;
  max-width: 1260px;
  margin: 47px auto 60px;

  @include breakpoint(large) {
    margin-bottom: 100px;
  }

  &__main {
    @include breakpoint(small only) {
      text-align: center;
      padding-right: 20px;
      margin-bottom: 81px;
    }

    @include breakpoint(medium down) {
      padding-left: 20px;
    }

    @include breakpoint(medium) {
      margin-right: 50px;
    }
  }

  .intro {
    margin-bottom: 0;
    max-width: none;
    padding: 0;

    @include breakpoint(medium) {
      text-align: left;
    }
  }

  &__post-date {
    @include subheading();
  }

  &__subheading {

    h2 {
      margin: 0 0 22px;
      @include subheading();
      font-size: 11px;
      line-height: 19px;
    }
  }

  &__content {
    h2 {
      color: #00263e;
      font-family: $primary-font;
      font-size: 38px;
      margin-bottom: 25px;
    }

    h3 {
      color: #00263e;
      font-family: $primary-font;
      font-size: 38px;
      margin-bottom: 25px;
    }

    p {
      @include body-copy;
    }

    li {
      @include bullet-list;

      a {
        color: get-color("mid-blue");
        transition: all 0.3s ease-in-out;
        text-decoration: underline;
        text-decoration-color: transparent;
        text-decoration-thickness: from-font;
        text-underline-offset: 2px;

        &:hover {
          text-decoration-color: get-color("mid-blue");
        }
      }
    }

    ul, ol {
      list-style: none;
      margin-left: 13px;
    }

    ul li:before {
    }

    p img {
      width: 100% !important;
      max-width: 100% !important;
      height: auto !important;
    }
  }

  &__sidebar {

    &-inner {
      @include breakpoint(small only) {
        padding: 35px 25px 120px;
      }

      padding: 35px 40px 120px;
      background: url("img/new/pattern-connected-q-white.svg") repeat-x;
      background-color: get-color("pale-blue");
      background-position: bottom;

      @include breakpoint(large) {
        padding: 40px 40px 120px;
      }
    }

    h3 {
      margin-bottom: 40px;
      color: get-color("dark-blue");
      font-family: $primary-font;
      font-size: 38px;

      @include breakpoint(small only) {
        text-align: left;
        font-size: 38px;
      }
    }

    ul, ol {
      margin-left: 13px;
      list-style-type: none;
    }

    li {
      margin-bottom: 36px;

      &:last-child {
        margin-bottom: 0;
      }

      a span {
        color: get-color("dark-blue");
        font-size: 12px;
        text-transform: uppercase;
        font-family: $secondary-font;
        font-weight: 600;
        letter-spacing: 0.1em;
      }
    }

    img {
      margin-bottom: 14px;
    }
  }
}
