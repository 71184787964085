.intro {
  width: 100%;
  max-width: 496px;
  margin: 0 auto 35px;
  padding: 58px 20px 39px;
  text-align: center;

  @include breakpoint(small only) {
    padding-top: 53px;
    margin-bottom: 20px;
  }

  h1 {
    @include intro-heading;
    color: get-color("dark-blue");
    margin-bottom: 5px;
  }

  h2 {
    @include subheading();
    margin-top: 15px;
    margin-bottom: 17px;
    font-weight: 600;
  }

  p {
    @include body-copy;
    margin-bottom: 19px;

    a {
      color: #333;
      transition: 0.3s all ease-in-out;

      &:hover {
        color: get-color('mid-blue');
        text-decoration: underline;
      }
    }
  }

  .multiple-buttons {
    padding-top: 15px;
    text-align: center;

    @include breakpoint(small only) {
      padding-top: 25px;
    }
  }
}
