.breadcrumb {
  text-align: center;
  padding-top: 11px;

  @include breakpoint(small only) {
    display: none;
  }

  &__list {
    display: inline-block;
    text-align: left;
    margin: 0;
    padding: 2px 5px 5px 5px;
    list-style-type: none;

    li {
      display: inline-block;
      white-space: nowrap;
      font-family: $secondary-font;
      letter-spacing: 0.1em;
      font-size: 7px;
      line-height: 14px;
      font-weight: 600;
      text-transform: uppercase;
      color: get-color("dark-blue");

      a {
        color: #8f8f8f;

        &:hover,
        &:focus-visible {
          color: get-color("mid-blue");
        }
      }

      &:nth-child(1n + 3) {
        &:before {
          content: "/";
          color: #8f8f8f;
        }
      }
    }
  }
}
