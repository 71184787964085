// DIY notification:
.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20000;
  padding: 30px 0 33px;
  display: none;
  background-color: get-color("white");

  &__inner {
    max-width: 1260px;
    margin: 0 auto;

    .cell {
      position: relative;

      @include breakpoint(small only) {
        padding: 0 20px;
      }
    }

    p {
      @include body-copy();
      color: get-color("slate") !important;
      margin: 0 160px 0 0;

      @include breakpoint(small only) {
        margin: 0 0 60px;
      }

      a {
        color: inherit;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;

        &:hover {
        }
      }
    }
  }

  &__accept {
    position: absolute !important;
    top: 0;
    right: 0;
    width: 90px;
    height: 35px;

    @include breakpoint(small only) {
      top: initial;
      right: initial;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }
}
