.other-rooms {
  position: relative;
  width: 100%;
  max-width: 1260px;
  margin: 100px auto 120px;

  @media screen and (min-width: 840px) {
    padding: 0 32px;
  }

  h2 {
    margin-bottom: 45px;
    color: #151d2f;
    font-family: $primary-font;
    font-size: 38px;
    text-align: center;
  }

  &__wrapper {
    width: 100%;
    overflow: hidden;
  }

  &__carousel {
    @media screen and (min-width: 840px) {
      width: 134%;
    }
  }

  &__slide {
    aspect-ratio: 324/377;
    @include corner-cut;
    padding: 0 18px;

    @media screen and (min-width: 840px) {
      aspect-ratio: 787/516;
      padding: 0 50px 0 0;
    }
  }

  &__inner {
    position: relative;
    min-height: 100%;
  }

  &__image {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: left bottom;
    transform: scale(0.83);
    transition: transform 0.3s ease-in-out;

    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 80%;
      left: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    position: absolute;
    bottom: 27px;
    left: 17px;
    z-index: 10;
    transition: opacity 0.3s ease-in-out;

    @media screen and (min-width: 840px) {
      bottom: 45px;
      left: 34px;
    }

    &--current {
      opacity: 0;
    }

    h3 {
      font-size: 25px;
      font-family: $primary-font;

      a {
        color: white;
      }
    }

    h4 {
      font-family: $primary-font;
      font-size: 25px;
      color: white;
      margin-bottom: 30px;
    }

    .multiple-buttons {
      margin: 0;
      text-align: left;
      color: white;

      .button {
        color: white;
        margin-left: 0px;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  .slick-arrow {
    position: absolute;
    z-index: 100;
    bottom: -60px;
    width: 15px;
    height: 34px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-indent: -12345px;
    background: no-repeat;
    background-size: cover;
    transform: rotate(-4deg);

    @media screen and (min-width: 840px) {
      top: 50%;
      bottom: initial;
    }

    &.slick-prev {
      left: 108px;
      background-position: left center;

      @media screen and (min-width: 840px) {
        left: 0px;
      }

      &:hover,
      &:active {
        background-image: url("img/new/chevron-left-orange.svg") !important;
      }
    }

    &.slick-next {
      right: 108px;
      background-position: right center;

      @media screen and (min-width: 840px) {
        right: 0px;
      }

      &:hover,
      &:active {
        background-image: url("img/new/chevron-right-orange.svg") !important;
      }
    }
  }

  .slick-current {
    .other-rooms {
      &__image {
        transform: scale(1);
      }

      &__content {
        @media screen and (min-width: 840px) {
          bottom: 65px;
          left: 70px;
        }

        &--current {
          opacity: 1;
        }

        &--not-current {
          opacity: 0;
        }

        h3 {
          font-size: 38px;
        }
      }
    }
  }
}
