.accordion {
  width: 100%;
  max-width: 1344px;
  margin: 63px auto 105px;
  padding: 0 42px;
  position: relative;

  @include breakpoint(small only) {
    margin: 45px auto 90px;
    padding: 0 18px;
  }

  &__inner {
    padding: 42px 63px;
    background-color: white;

    body.events & {
      background-color: get-color("pale-pink");

      @include breakpoint(small only) {
        background-color: get-color("white");
      }
    }

    @include breakpoint(small only) {
      padding: 36px 18px 45px;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      border-bottom: 1px solid black;
    }
  }

  .accordionButton {
    position: relative;
    border-top: 1px solid black;
    padding: 25px 40px 5px 0;
    cursor: pointer;

    @include breakpoint(small only) {
      padding: 25px 40px 10px 0;
    }

    h3 {
      @include price-accordion-heading;
      color: get-color("dark-blue");
    }

    &::after {
      content: "";
      position: absolute;
      top: 35px;
      right: 0;
      width: 14px;
      height: 14px;
      background: url("img/accordion-plus.svg") no-repeat;
      background-size: 14px 14px;
      transition: all 0.3s ease-in-out;
    }

    &.on::after {
      transform: rotate(45deg);
    }

    h3 {
    }
  }

  &__image {
    margin-bottom: 40px;

    img {
      width: 100%;
    }
  }

  &__content {
    padding: 15px 0 25px;

    ul {
      @include breakpoint(small only) {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    p,
    li {
      @include body-copy();

      @include breakpoint(small only) {
        text-align: center;
      }
    }

    li {
      @include bullet-list;
      font-weight: 400;
    }

    .multiple-buttons {
      text-align: left;
    }
  }

  &__diamond {
    left: 50%;
    bottom: 0;
    position: absolute;
    transform: translate(-50%, 36px);

    @include breakpoint(small only) {
      transform: translate(-50%, 36px);
    }

    path {
      body.events & {
        fill: get-color("pale-pink");

        @include breakpoint(small only) {
          fill: get-color("white");
        }
      }
    }
  }
}
