.header {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: get-color("dark-blue");
  transition: background-color 0.3s ease-in-out;
  padding: 0 42px;

  @include breakpoint(small only) {
    box-shadow: 0 0 20px rgba(#000, 0.3);
    padding: 0 18px;
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__off-canvas-toggle {
    position: absolute;
    top: 42px;
    left: 21px;
    cursor: pointer;
    width: 31px;
    height: 18px;

    @include breakpoint(small only) {
      top: 18px;
      left: 0px;
    }

    &-inner {
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 31px;
        height: 18px;
        transition: transform 0.3s ease-in-out;

        rect {
          transform-origin: left center;
          fill: get-color("white");
        }
      }

      .hover {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: 0.3s all ease-in-out;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &:hover,
    &:focus-visible {
      .closed {
        opacity: 0;
      }

      .hover {
        opacity: 1;
      }
    }
  }

  .button.header__off-canvas-toggle {
    border: none;

    &:hover,
    &:active {
      background-color: transparent;
    }
  }

  svg {
    height: auto;
  }

  &__open-booking {
    position: absolute;
    top: 36px;
    right: 21px;
    margin: 0;
    font-family: $primary-font;
    font-size: 30px;
    line-height: 1;
    color: get-color("white");
    transition: color 0.3s ease-in-out;

    @media screen and (max-width: $offcanvas-breakpoint) {
      position: fixed;
      top: auto;
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 35px;
      font-size: 23px;
      background-color: get-color("pale-blue");
      color: get-color("dark-blue");
      border: 1px solid get-color("pale-blue");
      padding: 7px 0 3px;

      body.events & {
        background-color: get-color("pink");
        border-color: get-color("pink");
      }

      body.front-of-house & {
        background-color: get-color("orange");
        border-color: get-color("orange");
      }

      body.dining & {
        background-color: get-color("peach");
        border-color: get-color("peach");
      }
    }
  }

  &__mobile-call {
    position: absolute;
    top: 18px;
    right: 0px;
    width: 25px;
    height: 25px;
    text-indent: -12345px;
    margin: 0;
    padding: 0;
    border: 0;
    background: none transparent;
    display: none;

    @media screen and (max-width: $offcanvas-breakpoint) {
      display: block;
    }

    img,
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__logo {
    width: 100%;
    max-width: 231px;
    margin: 0 auto;
    padding: 63px 0 15px;

    @media screen and (max-width: $offcanvas-breakpoint) {
      max-width: 213px;
      padding: 54px 0 18px;
    }

    &--transparent {
      display: none;
    }

    &--compact {
      max-width: 126px;
      width: 100%;

      @include breakpoint(small only) {
        max-width: 115px;
      }
    }

    svg {
      max-width: 231px;
      width: 100%;

      #Path_568,
      .cls-1 {
        fill: get-color("mid-blue");

        body.events & {
          fill: get-color("pink");
        }

        body.front-of-house & {
          fill: get-color("orange");
        }

        body.dining & {
          fill: get-color("peach");
        }
      }
    }
  }

  &__top-nav {
    position: absolute;
    top: 42px;
    font-family: $secondary-font;
    font-size: 10px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    &--left {
      left: 103px;
    }

    &--right {
      right: 180px;
    }

    @media screen and (max-width: $offcanvas-breakpoint) {
      display: none;
    }

    ul {
      margin: 0;
      list-style-type: none;
    }

    &--right {
      ul {
        margin-right: 10px;
      }
    }

    li {
      display: inline;
      margin-left: 21px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      position: relative;
      padding-left: 18px;
      color: get-color("white");

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) rotate(45deg);
        width: 6px;
        height: 6px;
        border: 1px solid transparent;
        transition: all 0.3s ease-in-out;
      }

      &:hover,
      &:focus-visible,
      &.active {
        color: get-color("mid-blue");
        background-color: transparent !important;

        &::before {
          background-color: get-color("mid-blue");
          border-color: get-color("mid-blue");
          transform: translateY(-50%) rotate(225deg);
        }
      }
    }
  }

  .button.header__mobile-call {
    border: none;
  }

  //////////////////////
  // Styles that are applied to only the transparent header
  //////////////////////
  &--transparent {
    background-color: transparent;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    box-shadow: none;
    padding-bottom: 40px;

    .header {
      &__open-off-canvas {
      }

      &__logo {
        &--transparent {
          display: block;
        }

        &--normal {
          display: none;
        }
      }

      &__mobile-call {
        top: 42px;
      }
    }
  }

  &.header--compact {
    box-shadow: none;
    height: 105px;

    @media screen and (max-width: $offcanvas-breakpoint) {
      height: 61px;
    }

    .header__logo {
      &--normal,
      &--transparent {
        display: none;
      }

      &--compact {
        display: block;

        @media screen and (max-width: $offcanvas-breakpoint) {
          width: 86px;
        }
      }
    }

    .header {
      &__top-nav {
        top: 50%;
        transform: translateY(-50%);
      }

      &__open-booking {
        top: 50%;
        transform: translateY(-50%);

        @media screen and (max-width: $offcanvas-breakpoint) {
          top: auto;
          bottom: 0;
          transform: translateY(0);
        }
      }

      &__off-canvas-toggle {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &.header--transparent {
    .header__logo {
      &--compact {
        display: none;
      }
    }
  }

  .header__logo--compact {
    display: none;
    padding: 22px 0 14px;

    @media screen and (max-width: $offcanvas-breakpoint) {
      padding: 9px 0;
    }
  }

  .button.header__mobile-call {
    margin-top: 4px;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }
}

.header__open-booking {
  &:hover,
  &:active,
  &:focus {
    color: get-color("mid-blue");
  }
}

body.no-slider {
  .header {
    height: 105px;

    @media screen and (max-width: $offcanvas-breakpoint) {
      height: 61px;
    }

    .header__off-canvas-toggle {
      top: 50%;
      transform: translateY(-50%);
    }

    .header__logo {
      &--normal,
      &--transparent {
        display: none;
      }

      &--compact {
        display: block;

        @media screen and (max-width: $offcanvas-breakpoint) {
          width: 86px;
        }
      }
    }
  }
}
