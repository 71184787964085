.meetings-detail {
  max-width: 1344px;
  width: 100%;
  margin: 0 auto;
  padding: 63px 42px;

  @include breakpoint(small only) {
    padding: 54px 0 36px;
  }

  h1 {
    @include intro-heading;
    color: get-color("dark-blue");
    text-align: left;
    margin-bottom: 9px;
  }

  h2 {
    @include subheading;
    text-align: left;
    margin-bottom: 21px;

    @include breakpoint(small only) {
      margin-bottom: 18px;
    }
  }

  h3 {
    @include price-accordion-heading;
    color: get-color("dark-blue");
    text-align: left;
    margin-bottom: 21px;

    @include breakpoint(small only) {
      margin-bottom: 18px;
    }
  }

  p {
    @include body-copy;
    text-align: left;
    margin-bottom: 21px;
  }

  ul {
    margin-bottom: 42px;

    @include breakpoint(small only) {
      margin-bottom: 36px;
    }
  }

  ul,
  li {
    text-align: left;
    margin-left: 7px;
    list-style-image: url("/assets/img/bullet.svg");
    @include bullet-list;
  }

  &__intro {
    padding-right: 4.15%;
    margin-bottom: 0px;

    @include breakpoint(small only) {
      padding: 0 18px;
      margin-bottom: 54px;
    }

    .multiple-buttons {
      text-align: left !important;
    }
  }

  &__img {
    position: relative;

    .slick-prev {
      position: absolute;
      top: 50%;
      left: -23px;
      transform: translateY(-50%);
      z-index: 9;
      text-indent: -222222px;
      background: url("img/new/chevron-left-dark.svg") no-repeat;
      background-size: 14px 33px;
      width: 14px;
      height: 33px;
      cursor: pointer;
      transition: 0.3s all ease-in-out;

      @include breakpoint(small only) {
        top: calc(100% + 21px);
        left: 18px;
      }

      &:hover,
      &:focus-visible {
        background: url("img/new/chevron-left-orange.svg") no-repeat;
        background-size: 14px 33px;
      }
    }

    .slick-next {
      position: absolute;
      top: 50%;
      right: -23px;
      transform: translateY(-50%);
      z-index: 9;
      text-indent: -222222px;
      background: url("img/new/chevron-right-dark.svg") no-repeat;
      background-size: 14px 33px;
      width: 14px;
      height: 33px;
      cursor: pointer;
      transition: 0.3s all ease-in-out;

      @include breakpoint(small only) {
        top: calc(100% + 21px);
        right: 18px;
      }

      &:hover,
      &:focus-visible {
        background: url("img/new/chevron-right-orange.svg") no-repeat;
        background-size: 14px 33px;
      }
    }

    &-container {
      position: relative;

      button.dropdown-activator {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        max-width: 150px;
        height: 21px;
        color: #fff;
        font-family: $secondary-font;
        text-align: left;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        padding: 0 20px 0 5px;
        background: get-color("mid-blue") url("img/chevron-down-white.svg")
          no-repeat right 5px center;
        background-size: 10px 10px;
      }
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 9;
    top: 21px;
    left: 0;
    width: 100%;
    max-width: 150px;
    background-color: rgba(get-color("mid-blue"), 0.9);

    &-content {
      ul {
        list-style: none;
        margin: 0;

        li {
          font-size: 11px;
          line-height: rem-calc(22);
          letter-spacing: 1px;
          padding: 2px 12px;
          list-style-image: none !important;

          button {
            text-transform: uppercase;
            color: #fff;
            font-family: $secondary-font;
            font-size: 11px;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            cursor: pointer;
            text-align: left;
            transition: color 0.3s ease-in-out;

            &:hover {
              color: get-color("dark-blue");
            }
          }
        }
      }
    }
  }

  &__tour {
    padding-top: 36px;

    @include breakpoint(small only) {
      padding: 63px 18px 0;
    }
  }
}
