.notice {
  max-width: 1344px;
  margin: 100px auto 150px;
  padding: 0 42px;

  @include breakpoint(small only) {
    margin: 54px auto;
    padding: 0;
  }

  &__inner {
    background-color: get-color("white");
    text-align: center;
    padding: 63px;

    @include breakpoint(small only) {
      padding: 54px 18px;
    }

    body.dining & {
      background-color: get-color("pale-peach");
    }

    body.events & {
      background-color: get-color("pale-pink");
    }
  }

  h2 {
    @include intro-heading;
    line-height: 70px !important;
    max-width: 630px;
    width: 100%;
    margin: 0 auto 21px;

    @include breakpoint(small only) {
      margin-bottom: 18px;
    }
  }

  h3 {
    @include subheading;
    color: get-color("dark-blue");
    max-width: 630px;
    width: 100%;
    margin: 0 auto 21px;

    @include breakpoint(small only) {
      margin-bottom: 18px;
    }
  }

  p {
    @include body-copy;
    max-width: 630px;
    width: 100%;
    margin: 0 auto 42px;

    @include breakpoint(small only) {
      margin-bottom: 36px;
    }
  }

  button {
    display: block;
    text-align: center;
  }
}
