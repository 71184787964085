.form {
  .g-recaptcha {
    margin-bottom: 20px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  select {
    width: 100%;
    height: 33px;
    background-color: get-color("pale-blue");
    font-family: $secondary-font;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    color: get-color("dark-grey");
    padding: 0 11px;
    border: none;
    transition: 0.3s all ease-in-out;
    border-radius: 0;

    &::placeholder {
      font-family: $secondary-font;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0.2em;
      color: get-color("dark-grey");
      text-transform: uppercase;
      transition: 0.3s all ease-in-out;
      opacity: 1;
    }

    &:focus-within {
      &::placeholder {
        opacity: 0;
      }
    }
  }

  textarea {
    width: 100%;
    padding: 11px;
    min-height: 210px;
    background-color: get-color("pale-blue");
    font-family: $secondary-font;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    color: get-color("dark-grey");
    border: none;
    transition: 0.3s all ease-in-out;
    border-radius: 0;

    &::placeholder {
      font-family: $secondary-font;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0.2em;
      color: get-color("dark-grey");
      text-transform: uppercase;
      transition: 0.3s all ease-in-out;
      opacity: 1;
    }

    &:focus-within {
      &::placeholder {
        opacity: 0;
      }
    }

    @include breakpoint(small only) {
      min-height: 180px;
    }
  }

  &__field-container {
    position: relative;

    &:has(input:focus-within, textarea:focus-within) {
      label.form__label {
        opacity: 0.34;
      }
    }

    &:has(input.event-date) {
      span#show-datepicker {
        position: absolute;
        right: 10px;
        top: 6px;
        width: 21px;
        height: 21px;
        background: url("img/new/calendar-icon.png");
        background-size: contain;
        cursor: pointer;
      }
    }
  }

  &__label {
    position: absolute;
    top: 2px;
    left: 4px;
    font-family: $secondary-font;
    font-size: 7px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: get-color("dark-grey") !important;
    opacity: 0;
    transition: 0.3s all ease-in-out;
  }

  label.error {
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: 600;
    color: #a70000;
    display: block;
    margin-bottom: 0px;
    transform: translateY(0);
  }

  &__checkbox {
    position: relative;
    text-align: left;
    margin-top: 10px;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      top: 0;
      z-index: 1;

      ~ label {
        position: relative;
        padding-left: 28px;
        padding-top: 1px;
        cursor: pointer;
        line-height: 1;
        display: block;
        font-family: $secondary-font;
        letter-spacing: 0.2em;
        color: get-color("dark-grey");
        font-size: 10px;
        font-weight: 300;
        text-transform: uppercase;

        &::before,
        &::after {
          content: "";
          position: absolute;
        }

        &::before {
          top: 0px;
          left: 0;
          width: 15px;
          height: 15px;
          border: none;
          background-color: get-color("pale-blue");
        }

        &::after {
          content: "";
          background-color: get-color("dark-blue");
          font-size: 12px;
          padding: 3px 0 0 1px;
          top: 2px;
          left: 2px;
          width: 11px;
          height: 11px;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        a {
          color: inherit;
          text-decoration: underline;
          transition: opacity 0.3s ease-in-out;

          &:hover,
          &:active {
            opacity: 0.7;
          }
        }
      }

      &:checked ~ label {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__submit {
    padding-top: 36px;

    @include breakpoint(medium) {
      padding-top: 0;
      margin-left: auto !important;
      text-align: right;
    }
  }

  .cell.medium-6,
  .cell.small-12 {
    margin-bottom: 30px;

    @include breakpoint(small only) {
      margin-bottom: 20px;
    }
  }

  textarea {
    margin-bottom: 30px;
  }

  .error {
    &::before,
    &::after {
      content: none;
    }
  }

  .selectric {
    height: 33px;
    background-color: get-color("pale-blue");

    span.label {
      height: 33px;
      font-family: $secondary-font;
      font-size: 10px;
      line-height: 1;
      font-weight: 600;
      color: get-color("dark-grey");
      text-transform: uppercase;
      transform: translateY(9px);
    }

    .button {
      height: 33px;

      &::before {
        content: none;
      }

      &::after {
        border-top-color: get-color("dark-grey");
      }
    }

    &-items {
      background-color: get-color("pale-blue");

      li {
        transition: 0.3s all ease-in-out;
        &.selected {
          background-color: get-color("dark-blue") !important;
          color: #fff !important;
        }

        &.disabled {
          opacity: 0.75 !important;
        }

        &:hover,
        &:focus-visible {
          background-color: get-color("dark-blue");
          color: #fff;
        }
      }
    }
  }
}
