.popup {
  @at-root .reveal-overlay {
    z-index: 60000;
  }

  &__inner {
    @include corner-cut;

    @include breakpoint(small only) {
      margin-top: 14px;
    }
  }

  &.reveal {
    max-width: 1260px;
    width: 90%;
    margin: 0 auto;
    border: 0;
    padding: 0;
    overflow: visible;
    background: transparent;
    backdrop-filter: none;
    top: 50% !important;
    transform: translateY(-50%);

    &:focus {
      outline: none;
    }
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 20px;
    height: 20px;
    background: url("img/new/close-cross-dark.svg") no-repeat center center;
    background-size: 20px 20px;
    text-indent: -12345px;
    z-index: 1;
    cursor: pointer;

    @include breakpoint(small only) {
      top: 15px;
      right: 15px;
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
    }
  }

  &__image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    background-color: get-color("white");
    padding: 94px 105px 94px 63px;

    @include breakpoint(small only) {
      padding: 42px 18px 36px;
      position: relative;
    }

    img {
      margin-bottom: 25px;
      width: 100%;
    }

    h2 {
      @include module-panel-heading;
      color: get-color("dark-blue");
      margin-bottom: 9px;

      @include breakpoint(small only) {
        padding-right: 18px;
      }
    }

    h3 {
      @include subheading;
      margin-bottom: 21px;
    }

    p {
      @include body-copy();
      color: get-color("dark-grey");
      margin-bottom: 42px;

      @include breakpoint(small only) {
        margin-bottom: 36px;
      }
    }
  }
}

.reveal-overlay {
  background-color: rgba(255, 255, 255, 0.84);
  backdrop-filter: blur(6px);
}
