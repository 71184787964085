@mixin intro-heading {
  font-family: $primary-font;
  font-size: 46px;
  line-height: 57px;
  color: get-color("dark-blue");

  @include breakpoint(medium) {
    font-size: 58px;
    line-height: 50px;
  }
}

@mixin subheading {
  font-family: $secondary-font;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 0.2em;
  font-weight: 600;
  text-transform: uppercase;
  color: get-color("mid-blue");
}

@mixin body-copy {
  font-family: $secondary-font;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: get-color("dark-grey");
}

@mixin module-panel-heading {
  font-family: $primary-font;
  font-size: 38px;
  line-height: 46px;
}

@mixin price-accordion-heading {
  font-family: $primary-font;
  font-size: 25px;
  line-height: 36px;
}

@mixin full-width-panel {
  font-family: $primary-font;
  font-size: 34px;
  line-height: 44px;

  @include breakpoint(medium) {
    font-size: 45px;
    line-height: 58px;
  }
}

@mixin bullet-list {
  font-family: $secondary-font;
  font-size: 15px;
  line-height: 29px;
  text-align: left;
  list-style-image: url("img/bullet.svg");

  body.events & {
    list-style-image: url("img/bullet-pink.svg");
  }

  body.front-of-house & {
    list-style-image: url("img/bullet-orange.svg");
  }

  body.dining & {
    list-style-image: url("img/bullet-peach.svg");
  }
}
