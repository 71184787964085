.offers-detail {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto 60px;
  padding-top: 50px;

  h1 {
    @include breakpoint(small only) {
      font-size: 46px;
    }
  }

  &__intro {
    text-align: left;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    max-width: none;

    @include breakpoint(small only) {
      margin-bottom: 80px;
    }

    @include breakpoint(medium down) {
      padding: 0 20px;
    }

    @include breakpoint(medium) {
      padding-right: 50px;
    }

    @include breakpoint(large) {
      margin-left: 8.33333%;
    }

    .multiple-buttons {
      text-align: left;
    }

    h2 {
      margin-bottom: 32px;

      @include breakpoint(small only) {
        margin-bottom: 18px;
      }
    }
  }

  &__price {
    font-size: 25px;
    font-family: $primary-font;
    margin: 32px 0px 21px;

    @include breakpoint(small only) {
      margin: 27px 0 18px;
    }
  }

  &__details {
    @include breakpoint(small only) {
      width: 92%;
      margin-left: auto;
      margin-right: auto;
    }

    @include breakpoint(medium only) {
      padding-right: 20px;
    }

    &-inner {
      padding: 40px 40px 120px;
      background: url("img/new/pattern-connected-q-white.svg") repeat-x;
      background-color: get-color("pale-blue");
      background-position: bottom;

      @include breakpoint(small only) {
        padding: 36px 18px 108px;

        h2 {
          font-size: 38px;
        }
      }

      @include breakpoint(large) {
        padding: 40px 40px 120px;
      }
    }

    h2 {
      margin-bottom: 5px;
      color: get-color("dark-blue");
      font-family: $primary-font;
    }

    p {
      @include body-copy();
      color: get-color("white");
    }

    ul {
      @include bullet-list();
      font-weight: 300;
      color: get-color("dark-blue");
      margin-left: 15px;
    }

    li:before {
      text-align: left;
      list-style-image: url("/assets/img/bullet.svg");
      @include bullet-list;
    }
  }
}
