.home {
  padding: 115px 42px 0;
  max-width: 1344px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(medium only) {
    padding: 115px 21px 0;
  }

  @include breakpoint(small only) {
    padding: 54px 0 0;
  }

  &__item {
    margin-bottom: 116px;
    align-items: center;

    @include breakpoint(small only) {
      margin-bottom: 0;
      padding: 0 18px 72px;
    }
  }

  &__image {
    @include corner-cut-right;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    h3 {
      font-weight: 600 !important;
    }

    p {
      margin-bottom: 26px !important;
      padding-right: 63px;

      &:last-of-type {
        margin-bottom: 42px !important;

        @include breakpoint(small only) {
          margin-bottom: 18px !important;
        }
      }

      @include breakpoint(small only) {
        padding-right: 0;
      }
    }

    &-inner {
      @include flex-align-center();
      text-align: left;
      padding-top: 105px;
      padding-bottom: 42px;
      padding-left: 21px;
      padding-right: 21px;

      @include breakpoint(medium) {
        padding-left: 8.3%;
      }

      @include breakpoint(small) {
        padding: 18px 0;
      }
    }

    &-detail-links {
      padding-top: 22px;

      &:empty() {
        padding-top: 0;
      }

      ul {
        margin: 0 auto;
        list-style: none;

        li {
          margin-bottom: 8px;
          width: 75%;

          @include breakpoint(small only) {
            width: 100%;
          }

          a {
            display: flex;
            justify-content: flex-start;
            gap: 20px;
            align-items: center;

            p {
              font-family: $primary-font;
              font-size: 20px;
              line-height: 36px;
              letter-spacing: 0.025em;
              color: get-color("dark-blue");
              position: relative;
              margin-bottom: 0 !important;
              padding: 0;

              &:last-of-type {
                margin-bottom: 0 !important;
              }
            }

            span {
              width: 24px;
              height: 1px;
              background-color: get-color("mid-blue");
              transition: all 0.5s ease-in-out;
            }

            &:hover {
              span {
                transform: translateX(50%);
              }
            }
          }
        }
      }
    }
  }

  &__item--image-right {
    @include breakpoint(medium) {
      padding-left: 0;
    }

    .home {
      &__image {
        @include corner-cut;

        @include breakpoint(medium) {
          order: 2;
        }
      }

      &__content {
        @include breakpoint(medium) {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          order: 1;
          padding-right: 63px;
          padding-left: 0px;
        }
      }
    }
  }

  h2 {
    @include module-panel-heading;
    color: get-color("dark-blue");
    margin-bottom: 9px;

    @include breakpoint(small only) {
      margin-top: 15px;
    }

    a {
      color: inherit;
    }
  }

  h3 {
    @include subheading();
    margin-bottom: 21px;

    @include breakpoint(small only) {
      margin-top: 15px;
    }

    a {
      color: inherit;
    }
  }

  p {
    @include body-copy();
    margin-bottom: 21px;
  }

  .multiple-buttons {
    margin-top: 15px;

    @include breakpoint(small only) {
      padding-top: 18px;
    }
  }
}
