.directions {
  position: relative;
  margin-bottom: 0;

  &__map {
    position: relative;
    text-align: left;
    min-height: calc(100vh - 61px);

    @include breakpoint(large up) {
      min-height: calc(100vh - 105px);
    }

    .map-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__intro {
    max-width: 540px;
    margin: 0 auto;
    padding: 21px 0;
    text-align: center;

    @include breakpoint(small only) {
      padding: 36px 18px 18px;
    }

    h2 {
      @include module-panel-heading;
      color: get-color("dark-blue");
      margin-bottom: 9px;
    }

    h3 {
      @include subheading;
      margin-bottom: 32px;

      @include breakpoint(small only) {
        margin-bottom: 27px;
      }
    }

    p {
      @include body-copy;
      margin-bottom: 32px;

      @include breakpoint(small only) {
        margin-bottom: 27px;
      }
    }
  }

  &__content {
    .form {
      width: 100%;
      max-width: 420px;
      margin: 0 auto;
      padding: 0 21px;

      @include breakpoint(small only) {
        padding: 0 18px;
      }

      button[type="submit"] {
        font-family: $primary-font;
        font-size: 25px;
        line-height: 1;
        color: get-color("dark-blue");
        padding-left: 21px;
        cursor: pointer;
        transition: 0.3s color ease-in-out;

        @include breakpoint(small only) {
          padding-left: 18px;
        }

        &:hover,
        &:focus-visible {
          color: get-color("orange");
        }
      }

      > div {
        > div {
          display: flex;
          align-items: center;
        }
      }
    }

    input[type="text"] {
      background-color: get-color("pale-blue");
      font-family: $secondary-font;
      font-size: 10px;
      line-height: 1;
      letter-spacing: 0.2em;
      font-weight: 600;
      text-transform: uppercase;
      height: 53px;
      padding: 0 21px;
      color: get-color("dark-blue");

      &::placeholder {
        color: get-color("dark-blue");
      }
    }
  }

  &__results {
    max-width: 1050px;
    margin: auto;
    width: 100%;
    margin-top: 42px;
  }
}
