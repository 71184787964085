.accommodation {
  width: 100%;
  max-width: 1344px;
  margin: 0 auto;
  padding: 35px 42px 63px;

  @include breakpoint(medium only) {
    padding: 35px 21px 42px;
  }

  @include breakpoint(small only) {
    padding: 0 18px 36px;
  }

  &-items {
    margin: 0 -24px;
    justify-content: center;

    @include breakpoint(small only) {
      margin: 0;
    }
  }

  &__items {
    justify-content: center;
  }

  &__item {
    padding: 0;
    margin-bottom: 21px;
    text-align: center;

    @include breakpoint(medium) {
      padding: 0 21px;
      margin-bottom: 123px;
    }

    &-inner {
    }
  }

  &__image {
    margin-bottom: 33px;

    @include breakpoint(small only) {
      margin-bottom: 27px;
    }
  }

  &__content {
    padding: 0 16px;

    @include breakpoint(large) {
      padding: 0 24px;
    }

    h2 {
      @include module-panel-heading;
      color: get-color("dark-blue");
    }

    h3 {
      @include subheading();
      margin-bottom: 21px;
    }

    h2,
    h3 {
      a {
        color: inherit;
      }
    }

    p {
      @include body-copy();
      margin-bottom: 21px;

      @include breakpoint(small only) {
        margin-bottom: 18px;
      }

      &:last-of-type {
        margin-bottom: 42px;

        @include breakpoint(small only) {
          margin-bottom: 36px;
        }
      }
    }
  }

  &__price {
    margin-top: -9px;

    @include breakpoint(small only) {
      margin-top: -5pz;
    }

    h4 {
      @include price-accordion-heading;
      color: get-color("dark-blue");
      margin-bottom: 31px;

      @include breakpoint(small only) {
        margin-bottom: 27px;
      }
    }
  }

  .multiple-buttons {
    text-align: center;
    padding: 0 0;

    @include breakpoint(small only) {
      margin-top: 15px;
    }
  }
}
