.standard-content {
  width: 100%;
  max-width: 824px;
  margin: 0 auto 147px;
  padding: 0 42px;
  text-align: center;

  @include breakpoint(medium only) {
    padding: 0 21px;
    margin-bottom: 105px;
  }

  @include breakpoint(small only) {
    padding: 0 18px;
    margin-bottom: 90px;
  }

  h2 {
    @include module-panel-heading;
    color: get-color("dark-blue");
    margin-bottom: 21px;
    padding: 10px 0;
  }

  h3 {
    @include subheading;
    margin-bottom: 30px;

    @include breakpoint(medium) {
      padding: 0 63px;
    }
  }

  ol {
    margin-bottom: 27px;
    list-style-position: inside;

    @include breakpoint(medium) {
      width: 100%;
      max-width: 80%;
      margin: 0 auto 32px;
    }

    @include breakpoint(large only) {
      width: 100%;
      max-width: 70%;
    }

    li {
      font-weight: 400;
      text-align: left;
    }
  }

  p,
  li {
    @include body-copy();

    a {
      color: get-color("mid-blue");

      &:hover,
      &:focus-visible {
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: from-font;
      }
    }
  }

  ul {
    list-style: none;
    list-style-position: inside;
    margin-left: 0;
    margin-bottom: 32px;
    padding: 0 18px;

    @include breakpoint(medium) {
      width: 100%;
      max-width: 80%;
      margin: 0 auto 32px;
      padding: 0;
    }

    @include breakpoint(large only) {
      max-width: 70%;
    }

    li {
      @include bullet-list;
      font-weight: 400;
    }
  }

  p {
    @include body-copy;
    text-align: center;
    margin-bottom: 18px;

    @include breakpoint(medium) {
      width: 100%;
      max-width: 80%;
      margin: 0 auto 27px;
    }

    @include breakpoint(large only) {
      max-width: 70%;
    }
  }

  &__image {
    margin-bottom: 32px;

    img {
      @include breakpoint(large) {
        max-width: 800px !important;
        width: 100% !important;
        height: auto;
      }
    }
  }

  &__cta {
    text-align: center;
    padding-top: 40px;
  }
}
