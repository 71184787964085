.filter {
  text-align: center;
  margin-bottom: 63px;

  @include breakpoint(medium only) {
    margin-bottom: 42px;
  }

  @include breakpoint(small only) {
    padding: 0 18px;
    margin-bottom: 45px;
  }

  &-controls-dropdown {
    .selectric {
      background-color: transparent;
      border: none;
      min-width: 150px;

      &-items {
        background-color: get-color("mid-blue");
        border: 1px get-color("mid-blue") solid;
        box-shadow: none;
        margin-top: 10px;
      }

      &-scroll {
        &::before {
          content: "";
          position: absolute;
          height: 20px;
          width: 20px;
          background-color: get-color("mid-blue");
          border: 1px get-color("mid-blue") solid;
          transform: rotate(45deg) translate(-14px, -1px);
        }
        background-color: get-color("mid-blue");

        .selected {
          background-color: get-color("mid-blue");
        }

        .highlighted {
          background-color: get-color("mid-blue");
        }

        ul {
          li {
            font-family: $secondary-font;
            text-transform: uppercase;
            font-size: 11px;
            color: #333333;
            letter-spacing: 0.2em;
          }
        }
      }

      &-wrapper {
      }

      span {
        color: get-color("mid-blue");
        text-transform: uppercase;
        font-family: $secondary-font;
        font-size: 11px;
        letter-spacing: 0.2em;
      }

      .button {
        background-color: transparent;
        border: none;
        top: -5px;

        &::before {
          background-color: transparent;
          opacity: 0;
        }
        &::after {
          content: "";
          background-size: 13px 14px !important;
          border-top: none;
          height: 21px;
          position: absolute;
          right: -25px;
          top: 17px;
          width: 31px;
          background: url("/assets/img/arrow-down.svg") no-repeat;
        }
      }
    }
  }
  &__inner {
    background-color: transparent;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;

    span {
      color: get-color("mid-blue") !important;
      letter-spacing: 0.2em;
      font-size: 11px;
      font-weight: 600;
    }
  }

  &__select {
    width: 210px;
  }

  &__buttons {
    display: flex !important;
    align-items: center;

    @include breakpoint(small only) {
      gap: 10px;
    }

    margin-bottom: 2px;

    .button {
      font-size: 11px;

      &::before {
        border: none;
        opacity: 0;
      }
    }

    @include breakpoint(small only) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  span,
  .offers__filter-buttons,
  &__buttons {
    display: inline-block;
  }

  span {
    color: get-color("slate");
    text-transform: uppercase;
    font-family: $secondary-font;
    color: #333333;
  }

  a,
  button {
    position: relative;
    text-transform: uppercase;
    font-family: $secondary-font;
    color: #333333;
    margin: 0px 25px 0 15px;
    padding: 0;
    border: 0;
    background-color: transparent;
    font-weight: 400;

    &:hover,
    &.on {
      background-color: transparent;
      color: get-color("mid-blue");

      &::after {
        opacity: 1;
      }
    }
  }

  select {
    border: 0;

    background-color: transparent;
    margin-left: 15px;
  }

  .selectric {
    span.label {
      color: get-color("dark-grey") !important;
    }
  }
}
