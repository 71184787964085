.menus {
  width: 100%;
  margin: 0 auto 120px;
  background-color: get-color("pale-peach");
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 47px;
    height: 47px;
    background-color: get-color("pale-peach");
    z-index: 0;
    pointer-events: none;
  }

  &__inner {
    padding: 63px 18px;
    width: 100%;

    @include breakpoint(small only) {
      padding: 83px 18px;
    }

    @include breakpoint(medium) {
      padding: 63px 42px;
    }

    @include breakpoint(large) {
      padding: 63px;
    }
  }

  &__image {
    @include breakpoint(medium) {
      transform: translateX(-21px);
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__heading {
    h2 {
      @include module-panel-heading;
      color: get-color("dark-blue");
      margin-bottom: 18px;
    }
  }

  &__content {
    padding-left: 21px;

    @include breakpoint(small only) {
      padding-top: 36px;
      padding-left: 0;
    }
  }

  &__list {
    margin: 0 0 63px;
    list-style-type: none;

    @include breakpoint(small only) {
      margin-bottom: 36px;
    }

    li {
      position: relative;
      padding-right: 42px;
      @include body-copy;
      margin-bottom: 5px;

      a {
        color: inherit;
      }

      span {
        font-size: 12px !important;
        color: get-color("dark-blue");
        position: absolute;
        font-weight: 600;
        top: 3px;
        right: 0;
      }
    }
  }

  &__times {
    h3 {
      @include price-accordion-heading;
      color: get-color("dark-blue");

      @include breakpoint(small only) {
        margin-bottom: 15px;
      }
    }

    ul {
      margin: 0 0 21px;
      list-style-type: none;
    }

    li,
    p {
      @include body-copy();
    }
  }
}
