.button {
  position: relative;
  border: none;
  background-color: transparent;
  color: get-color("dark-blue");
  font-size: 12px;
  line-height: 1;
  font-family: $secondary-font;
  font-weight: 600;
  letter-spacing: 0.1em;
  padding: 0 0 0 27px;
  text-transform: uppercase;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  background-color: transparent !important;

  @include breakpoint(medium only) {
    padding: 0 0 0 18px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    border: 1px solid get-color("mid-blue");
    transition: all 0.4s ease-in-out;
  }

  &:hover,
  &:focus-visible {
    color: get-color("dark-blue");
    background-color: transparent !important;

    &::before {
      background-color: get-color("mid-blue");
      transform: translateY(-50%) rotate(225deg);

      body.events & {
        background-color: get-color("pink");
        border-color: get-color("pink");
      }

      body.front-of-house & {
        background-color: get-color("orange");
        border-color: get-color("orange");
      }

      body.dining & {
        background-color: get-color("peach");
        border-color: get-color("peach");
      }
    }
  }
}

.multiple-buttons {
  text-align: left;
  width: calc(100% + 26px);
  margin-left: -13px;
  margin-right: -13px;

  .button {
    margin: 0 13px 13px;

    @include breakpoint(medium only) {
      margin: 0 8px 13px;
    }
  }
}

// body.events {
//   .button {
//     &:hover,
//     &:focus-visible {
//       &::before {
//         background-color: get-color("pink");
//         border-color: get-color("pink");
//       }
//     }
//   }
// }

// body.front-of-house {
//   .button {
//     &:hover,
//     &:focus-visible {
//       &::before {
//         background-color: get-color("orange");
//         border-color: get-color("orange");
//       }
//     }
//   }
// }

// body.dining {
//   .button {
//     &:hover,
//     &:focus-visible {
//       &::before {
//         background-color: get-color("peach");
//         border-color: get-color("peach");
//       }
//     }
//   }
// }
