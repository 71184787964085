.terms {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto 120px;

  @include breakpoint(medium down) {
    padding: 0 20px;
  }

  &__inner {
    @include breakpoint(medium) {
      padding-right: 50px;
    }
  }

  h2 {
    font-family: $primary-font;
    color: get-color("dark-blue");
    font-size: 25px;
    line-height: 36px;
  }

  &__content {
    p {
      font-family: $secondary-font;
      font-size: 12px !important;
      line-height: 16px !important;
      font-weight: 300;
    }
  }
  ul {
    li {
      @include bullet-list;
    }
  }
  p,
  li {
    color: get-color("dark-grey");
  }
}
