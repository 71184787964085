.blog-landing {
  text-align: center;

  &__inner {
    max-width: 1344px;
    width: 100%;
    margin: 0 auto;
    padding: 0 42px;

    @include breakpoint(medium only) {
      padding: 0 21px;
    }

    @include breakpoint(small only) {
      padding: 0;
    }
  }

  &__item {
    margin-bottom: 42px;

    @include breakpoint(large) {
      margin-bottom: 130px;

      &:last-child {
        margin-bottom: 128px;
      }
    }

    @include breakpoint(small only) {
      margin-bottom: 52px; 

      &:last-child {
        margin-bottom: 50px;
      }
    }

    &--highlighted {
      .blog-landing__subheading {
        h2 {
          color: get-color("dark-blue");
          margin-bottom: 11px;
        }
      }
    }

    &:not(.blog-landing__item--highlighted) {
      @include breakpoint(medium only) {
        width: calc(50% - 13px);
        display: inline-block;
      }

      @include breakpoint(large) {
        width: calc(50% - 34px);
        display: inline-block;
      }

      &:nth-child(even) {
        @include breakpoint(medium only) {
          margin-right: 21px;
        }

        @include breakpoint(large) {
          margin-right: 62px;
        }

        &:last-child {
          @include breakpoint(large) {
            transform: translateX(34px);
          }
        }
      }
    }
  }

  &__image {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    padding: 30px 21px;
    text-align: center;

    @include breakpoint(small only) {
      padding: 34px 21px;
    }

    @include breakpoint(large) {
      padding: 30px 42px;
    }

    &:is(.blog-landing__item--highlighted .blog-landing__content) {
      background-color: get-color("pale-blue") !important;
      text-align: center;
      background: url("img/new/pattern-connected-q-white.svg") repeat-x;
      background-position: bottom;
      text-align: center;
      padding: 98px 63px;

      @include breakpoint(medium only) {
        padding: 98px 21px;
      }

      @include breakpoint(small only) {
        padding: 54px 18px 98px;
      }
    }

    h2 {
      margin-bottom: 0;
      font-size: 38px;
      font-family: $primary-font;

      a {
        color: inherit;
      }
    }

    p {
      @include body-copy();
    }
  }

  &__post-date {
    @include subheading();
    margin-bottom: 13px;
  }

  &__subheading {
    h2,
    h3 {
      @include subheading();
      margin-bottom: 26px;
    }
  }

  &__cta {
    padding-top: 29px;

    &:is(.blog-landing__item--highlighted .blog-landing__cta) {
      padding-top: 16px;

      @include breakpoint(small only) {
        padding-top: 16px;
      }
    }

    .button {
      margin-bottom: 0;
    }

    @include breakpoint(small only) {
      padding-top: 10px;
    }
  }
}
