.gallery {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto 120px;
  padding: 0 10px;

  @include breakpoint(large) {
    padding: 0 30px;
  }

  &__item {
    width: 33%;
    float: left;
    padding: 10px;

    @include breakpoint(medium only) {
      width: 50%;
    }

    @include breakpoint(small only) {
      width: 100%;
    }

    &-sizer {
      width: 33%;
      padding: 10px;

      @include breakpoint(medium only) {
        width: 50%;
      }

      @include breakpoint(small only) {
        width: 100%;
      }
    }

    a {
      position: relative;
      display: block;

      &::before {
        content: "";
        position: absolute;
        z-index: 5;
        bottom: 0;
        right: 0;
        width: 14px;
        height: 40px;
        background: url("img/accordion-plus.svg") no-repeat center center;
        background-size: 14px 14px;
      }

      img {
        margin-bottom: 10px;
      }

      p {
        font-family: $primary-font;
        font-size: 25px;
        color: #151d2f;
      }
    }
  }

  &__modal {
    padding: 0;

    &-inner {
      position: relative;
      width: 83.3333%;
      margin: 0 auto;
      padding: 70px 0;

      @include breakpoint(large) {
        padding: 120px 0;
      }
    }

    &-close {
      position: absolute;
      top: 42px;
      left: auto;
      right: -63px;
      width: 37px;
      height: 37px;
      cursor: pointer;
      background-color: get-color("dark-blue");
      text-indent: -12345px;
      transform: rotate(45deg);

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 20px;
        height: 20px;
        transition: 0.3s all ease-in-out;
      }

      &:hover,
      &:focus-visible {
        img {
          transform: translate(-50%, -50%) rotate(0deg);
        }
      }

      @include breakpoint(small only) {
        top: 15px;
        right: 0px;
        left: auto;
        width: 30px;
        height: 30px;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    &-image {
      position: relative;
    }

    &-prev,
    &-next {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      width: 21px;
      height: 21px;
      text-indent: -12345px;
      cursor: pointer;
      background: no-repeat;
      background-size: 27px 21px;
      transition: all 0.3s ease-in-out;

      &:hover,
      &:active {
        width: 27px;
      }
    }

    &-prev {
      left: -30px;
      background-image: url("img/new/chevron-left-dark.svg");
      background-position: left center;

      @include breakpoint(large) {
        left: -75px;
      }

      &:hover,
      &:focus-visible {
        background-image: url("img/new/chevron-left-orange.svg");
      }
    }

    &-next {
      right: -30px;
      background-image: url("img/new/chevron-right-dark.svg");
      background-position: right center;

      @include breakpoint(large) {
        right: -75px;
      }

      &:hover,
      &:focus-visible {
        background-image: url("img/new/chevron-right-orange.svg");
      }
    }

    &-content {
      padding: 12px 0 0;
    }

    &-caption {
      font-family: $primary-font;
      color: #151d2f;
      font-size: 25px;

      @include breakpoint(small only) {
        text-align: center;
        margin-bottom: 15px;
      }
    }

    &-cta {
      text-align: center;
      font-family: $primary-font;
      color: #151d2f;
      font-size: 25px;

      @include breakpoint(medium) {
        text-align: right;
      }

      .button {
        margin-left: 90px;
        margin-top: 12px;

        @include breakpoint(small only) {
          margin: 0px 20px;
        }
      }
    }

    &-iframe {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.gallery__modal.reveal {
  background-color: hsla(0, 0%, 100%, 1);
}
