.intro-info {
  max-width: 946px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(small only) {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  &__wrapper {
    background-color: white;
    padding: 120px 0px 80px;

    @include breakpoint(small only) {
      padding: 60px 0 40px;
    }
  }

  h2 {
    @include intro-heading;
    font-family: $secondary-font;
    margin-bottom: 10px;
  }

  p {
    @include body-copy;
  }

  &__content {
    &-component {
      padding: 25px 50px 70px;
      background-color: get-color("white");

      body.events & {
        background-color: get-color("pale-pink");
      }

      body.dining & {
        background-color: get-color("pale-peach");
      }

      &-content {
        border-bottom: 1px get-color("dark-blue") solid;
      }

      h2 {
        font-family: $primary-font;
        font-size: 25px;
        margin-bottom: 0px;
        margin-top: 20px;
      }

      p {
        @include body-copy;
        margin-bottom: 20px;
      }
    }
  }

  &__button {
    width: 100%;
    margin-bottom: 0px;
    background-color: get-color("dark-blue");
    color: white;
    text-transform: uppercase;
    padding: 5px 0 11px;

    span {
      font-family: $secondary-font;
      color: get-color("white");
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.1em;
      cursor: pointer;
      position: relative;

      &::after {
        background: url("img/chevron-down-white.svg") no-repeat;
        background-size: contain;
        border-top: none;
        position: absolute;
        content: "";
        width: 18px;
        height: 8px;
        top: 50%;
        right: -27px;
        transform: translateY(-50%);
      }
    }
  }

  &__cta {
    margin-top: 30px;
  }

  &__info {
    display: flex;
    align-items: center;
    background-color: get-color("white");
    margin-top: 25px;

    @include breakpoint(small only) {
      flex-direction: column-reverse;
      margin-top: 54px;
    }

    body.events & {
      background-color: get-color("pale-pink");
    }

    body.dining & {
      background-color: get-color("pale-peach");
    }

    &-picture-container {
      @include breakpoint(medium) {
        padding: 50px;
      }
    }

    &-text-container {
      padding: 21px 21px 42px;

      @include breakpoint(medium) {
        padding: 50px 0px 50px 50px;
      }

      h2 {
        font-size: 35px;
        font-family: $primary-font;
      }
    }
  }
}

.extra {
  display: none;
}

.read-less {
  span {
    &::after {
      background: url("img/chevron-down-white.svg") no-repeat;
      background-size: contain;
      border-top: none;
      position: absolute;
      content: "";
      width: 18px;
      height: 8px;
      top: 50%;
      right: -27px;
      transform: rotate(180deg) translateY(50%);
    }
  }
}
