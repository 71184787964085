@charset 'utf-8';

$offcanvas-breakpoint: 970px;

$foundation-palette: (
  primary: #535a52,
  // very dark grey/geeen
  secondary: #767676,
  // default
  success: #3adb76,
  // default
  warning: #ffae00,
  // default
  alert: #cc4b37,
  // default
  "black": #000,
  "white": #fff,
  "grey": #bcbcbc,
  "light-grey": #d1d1d1,
  "gold": #847142,
  "claret": #810000,
  "marble-blue": #4f707b,
  "snow-drift": #f9f9f9,
  "slate": #535659,
  "granite": #333333,
  "stone": #d6d6d6,

  //new colours
  "dark-blue": #00263e,
  "mid-blue": #6aa2b8,
  "pale-blue": #c7dae2,
  "dark-grey": #333333,
  "pale-grey": #f3f3f4,
  "pink": #ffb2ba,
  "pale-pink": #fce8ea,
  "orange": #ff7f40,
  "peach": #ffc27a,
  "pale-peach": #fff6eb,
);

$body-background: #f3f3f4;

$primary-font: "Ginger", sans-serif;
$secondary-font: "gill-sans-nova", sans-serif;
